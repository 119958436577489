@import "styles/variables";
// ToDo refactor

$count: 16;
$duration: 0.9s;
$delay: 0.1s;

@mixin delays($to, $dur) {
  @for $index from 0 through $to {
    &:nth-child(#{$index}) .row {
      animation-delay: 1s * $dur * $index;
    }
  }
}

.row {
  grid-area: 1/1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  font-family: $FigginsSansV3;
  padding: 32px 0 0;
  color: $white;

  @media (max-width: $md) {
    padding: 24px 0 2px;
  }

  @media (max-width: $sm) {
    padding: 24px 0 12px;
    align-items: flex-end;
  }

  & a {
    transition: all 0.3s ease;
  }

  &.animation {
    animation-name: blink;
    animation-duration: $duration * $count;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-iteration-count: infinite;
    + .row {
      opacity: 0;
      animation-name: blink-inverse;
    }
  }
}

.cityWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;

  @media (max-width: $lg) {
    width: 80%;
  }

  @media (max-width: $md) {
    width: 90%;
  }

  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.city {
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 4px;

  @media (max-width: $md) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: $sm) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 0;
  }
}

.count {
  text-align: left;
  min-width: 112px;
  display: flex;
  align-items: flex-end;
  min-width: 280px;
  padding-bottom: 11px;
  font-size: 20px;
  line-height: 1.25;

  @media (max-width: $md) {
    min-width: 230px;
    font-size: 18px;
    padding-bottom: 9px;
  }

  @media (max-width: $sm) {
    font-size: 16px;
    margin-top: 1px;
    padding-bottom: 0;
  }
}

.specialLabel {
  position: relative;
  margin-left: 16px;
  margin-bottom: -4px;
  @media (max-width: $md) {
    margin-left: 10px;
    margin-bottom: -2px;
    display: flex;
    align-items: flex-end;
  }
  @media (max-width: $sm) {
    margin-bottom: -4px;
  }
  & svg {
    position: absolute;
    z-index: -1;
    height: 36px;
    width: 100%;
    @media (max-width: $md) {
      height: 26px;
    }
  }
  & div {
    padding: 9px 16px 7px;
    color: $black;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    @media (max-width: $md) {
      font-size: 12px;
      padding: 7px 10px 5px;
    }
  }
}

.arrowRightRotate {
  transform: rotate(0);
  transition: all 0.3s ease;

  &.arrowRight {
    transform: rotate(45deg);
  }

  width: 56px;
  height: 56px;

  @media (max-width: $md) {
    width: 48px;
    height: 48px;
  }

  @media (max-width: $sm) {
    width: 32px;
    height: 32px;
  }
}

.grid {
  display: grid;
  cursor: pointer;

  @include delays($count, 0.1);
  border-bottom: 1px solid $gray250;

  &:first-child {
    border-top: 1px solid $gray250;
  }

  &.gray {
    opacity: 0.5;
    cursor: default;
    & a {
      cursor: default;
    }
  }
}

.tooltip {
  @media (hover: none) {
    display: none;
  }
  & * {
    border: none !important;
  }
}

.cityPhoto {
  width: 260px;
  height: 156px;
  padding: 0 !important;
  display: flex !important;
  align-items: flex-end;
  border: none !important;
  border-radius: 0 !important;
  opacity: 1 !important;
  & div {
    width: 100%;
    svg {
      color: $black;
    }
  }
  &.gold {
    background-color: $gold200;
  }
  &.red {
    background-color: $red100;
  }
  &.purple {
    background-color: $purple100;
  }
}

.gold {
  color: $gold200;
}
.red {
  color: $red100;
}
.purple {
  color: $purple100;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink-inverse {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
