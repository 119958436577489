@import "styles/variables";

.wrapper {
  position: relative;
  width: 75px;
}

.option {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Figgins Sans V3 Bold";
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  padding: 16px 12px 10px;
  max-height: 42px;
  color: $white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  z-index: 2;
  line-height: 1;

  &.white {
    color: $black;
  }

  &:hover {
    @media (hover: hover) {
      color: $gold300;
    }
  }

  &.gold {
    color: $black;
    &:hover {
      @media (hover: hover) {
        color: $gray250;
      }
    }
  }

  & .arrowIcon {
    pointer-events: none;
    margin-left: 5px;
    display: inline-flex;
    transition: all 0.3s ease;
    transform: translateY(-10%);
  }

  &.opened {
    border-color: $gold300;
    color: $gold300;
    background-color: $gray400;

    &.white {
      background-color: $white;
    }

    &.gold {
      background-color: $gold300;
    }

    &:hover {
      background-color: $gold300;
      color: $black;
    }

    &:not(:last-child, .bottom) {
      border-bottom: none;
    }

    &.bottom {
      &:not(:first-child) {
        border-top: none;
        border-bottom: 1px solid $gold300;

        &.gold {
          border-color: $black;
        }
      }
    }

    &.white {
      &:hover {
        color: $white;
      }
    }

    &.gold {
      border-color: $black;
      color: $black;

      &:hover {
        background-color: $black;
        color: $gold300;
      }
    }

    & .arrowIcon {
      transform: rotate(180deg) translateY(20%);
    }
  }
}

.items {
  position: absolute;
  left: 0;
  right: 0;
  bottom: auto;
  z-index: 1;

  &.bottom {
    bottom: 40px;
  }
}
