@import "styles/variables";

.footerNavMenu {
  grid-template-rows: repeat(4, minmax(0, min-content));
  grid-template-columns: repeat(2, minmax(144px, auto));

  @media (min-width: $sm) {
    grid-template-rows: repeat(3, minmax(0, min-content));
    grid-template-columns: repeat(3, minmax(162px, auto));
  }
  @media (min-width: $lg) {
    grid-template-rows: repeat(4, minmax(0, min-content));
    grid-template-columns: repeat(2, minmax(min-content, auto));
  }
}
